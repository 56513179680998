import { useEffect, useState } from "react";
import pass, { Validation } from "../../models/validation.model";
import { AccountService } from "../../services/account.service";
import { useSnackbar } from "notistack";
import { CustomerService } from "../../services/customer.service";
import { Customer } from "../../models/customer.model";
import { fanfluencers } from "../../config";
import { useTranslation } from "react-i18next";

function CustomerRegisterViewModel() {
    const { t } = useTranslation();
    const [model, setModel] = useState({ email: '', firstname: '', lastname: '', status: 'approved', followers: 0, ratings: '', password: '', confirm: '' });
    const [errors, setErrors] = useState<Validation[]>([]);
    const [submitted, setSubmitted] = useState(false);
    const [processing, setProcessing] = useState(false);

    const customerService: CustomerService = new CustomerService();
    const accountService: AccountService = new AccountService();

    const { enqueueSnackbar } = useSnackbar();

    const rules = [
        {
            field: "email",
            type: "required",
            message: "error.emailrequire",
        },
        {
            field: "firstname",
            type: "required",
            message: "error.firstnamerequire",
        },
        {
            field: "firstname",
            type: "maxlength",
            length: 50,
            message: "error.mustnotexceed50",
        },
        {
            field: "lastname",
            type: "required",
            message: "error.lastnamerequire",
        },
        {
            field: "lastname",
            type: "maxlength",
            length: 50,
            message: "error.mustnotexceed50",
        },
        {
            field: "password",
            type: "required",
            message: "error.passwordrequire",
        },
        {
            field: "password",
            type: "minlength",
            length: 6,
            message: "error.mustnotlessthan5",
        },
        {
            field: "confirm",
            type: "required",
            message: "error.confirmrequire",
        },
        {
            field: "confirm",
            type: "minlength",
            length: 6,
            message: "error.mustnotlessthan5",
        }
    ];

    useEffect(() => {
        setModel({ ...model, ratings: getRatings(Number(model.followers)) });
    }, [model.followers])

    const change = (field: string, value: any) => {
        setModel({ ...model, [field]: value });
        if (submitted) isValid();
    }

    const getRatings = (follower: number) => {
        let value: string = '';
        fanfluencers.forEach(x => {
            if (x.minimum <= follower && x.maximum >= follower) {
                value = x.id;
            }
        });
        return value;
    };

    const getError = (field: string): Validation | undefined => {
        return errors.find(x => x.field === field);
    }

    const getErrorMessage = (field: string): string | undefined => {
        let message: string | undefined = undefined;
        if (errors.find(x => x.field === field)) {
            message = t(errors.find(x => x.field === field)!.message)
        }
        return message;
    }

    const isValid = (): boolean => {
        let values: Validation[] = [];
        rules.forEach(rule => {
            let validation: Validation = rule as Validation;
            if (pass(validation, model[validation.field as keyof typeof model]) === false) {
                values.push(validation);
            }
        })
        setErrors([...values]);
        return values.length === 0;
    }

    const onReset = () => {
        setSubmitted(false);
        setModel({ email: '', firstname: '', lastname: '', status: 'approved', followers: 0, ratings: '', password: '', confirm: '' });
        setErrors([]);
    }

    const onSubmit = async (): Promise<boolean> => {
        return new Promise(async resolve => {
            setSubmitted(true);
            setProcessing(true);
            if (isValid() === false) {
                resolve(false);
                setProcessing(false);
                return;
            }

            if (model.password !== model.confirm) {
                let validation: Validation = new Validation();
                validation.field = 'confirm';
                validation.type = 'mismatch';
                validation.message = t('error.passwordmismatch');
                setErrors([validation]);
                resolve(false);
                setProcessing(false);
                return;
            }

            let customers: Customer[] = await customerService.searchBy('email', model.email) as Customer[];
            if (customers.length > 0) {
                let validation: Validation = new Validation();
                validation.field = 'email';
                validation.type = 'inuse';
                validation.message = t('error.emailused');
                setErrors([validation]);
                resolve(false);
                setProcessing(false);
                return;
            }

            let account = await accountService.searchEmail(model.email);
            if (account.length > 0) {
                let validation: Validation = new Validation();
                validation.field = 'email';
                validation.type = 'inuse';
                validation.message = t('error.emailisnotsupplier');
                setErrors([validation]);
                resolve(false);
                setProcessing(false);
                return;
            }

            let id: string = await accountService.register(model.email, model.password);
            let customer: Customer = new Customer();
            customer.id = id;
            customer.email = model.email;
            customer.firstname = model.firstname;
            customer.lastname = model.lastname;
            customer.status = model.status as "pending" | "approved" | "suspend" | "deleted";
            customer.followers = model.followers ? model.followers : 0;
            customer.ratings = model.ratings;
            customer.active = true
            await customerService.set(id, customer);

            enqueueSnackbar(t('text.customercreated'), {
                variant: "success",
                anchorOrigin: {
                    vertical: "top",
                    horizontal: "right",
                },
            });

            setSubmitted(false);
            resolve(true);
        });
    }

    return { model, change, processing, getError, getErrorMessage, onSubmit, onReset }
}

export default CustomerRegisterViewModel;