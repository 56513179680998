import { useState } from "react";
import { BrandUser } from "../../models/brand.model";
import pass, { Validation } from "../../models/validation.model";
import { useSnackbar } from "notistack";
import { SupplierUserService } from "../../services/supplieruser.service";
import { AccountService } from "../../services/account.service";
import { useTranslation } from "react-i18next";

function SupplierUserRegisterViewModel(brandid: string) {
    const { t } = useTranslation();

    const [model, setModel] = useState({
        firstname: '',
        lastname: '',
        email: '',
        active: false,
        password: '',
        confirm: ''
    });
    const [errors, setErrors] = useState<Validation[]>([]);
    const [submitted, setSubmitted] = useState(false);
    const [process, setProcess] = useState(false);

    const accountService: AccountService = new AccountService();
    const supplierUserService: SupplierUserService = new SupplierUserService();

    const { enqueueSnackbar } = useSnackbar();

    const rules: any[] = [
        {
            field: "firstname",
            type: "required",
            message: 'error.firstnamerequire'
        },
        {
            field: "lastname",
            type: "required",
            message: 'error.lastnamerequire'
        },
        {
            field: "email",
            type: "required",
            message: 'error.emailrequire'
        },
        {
            field: "email",
            datatype: "email",
            type: "valid",
            message: 'error.notavalidemail'
        },
        {
            field: "password",
            type: "required",
            message: 'error.passwordrequire'
        },
        {
            field: "password",
            type: "minlength",
            length: 6,
            message: "error.mustnotlessthan5",
        },
        {
            field: "confirm",
            type: "required",
            message: 'error.confirmrequire'
        },
        {
            field: "password",
            type: "same",
            other: "confirm",
            message: 'error.passwordmismatch'
        },
        {
            field: "confirm",
            type: "same",
            other: "password",
            message: 'error.passwordmismatch'
        },
        {
            field: "confirm",
            type: "minlength",
            length: 6,
            message: "error.mustnotlessthan5",
        }
    ]

    const change = (field: string, value: any) => {
        setModel({ ...model, [field]: value });
        if (submitted) isValid();
    };

    const getError = (field: string): Validation | undefined => {
        return errors.find(x => x.field === field);
    }

    const getErrorMessage = (field: string): string | undefined => {
        let message: string | undefined = undefined;
        if (errors.find(x => x.field === field)) {
            message = t(errors.find(x => x.field === field)!.message)
        }
        return message;
    }

    const isValid = (): boolean => {
        let values: Validation[] = [];
        rules.forEach(rule => {
            let validation: Validation = rule as Validation;
            if (pass(validation, model[validation.field as keyof typeof model]) === false) {
                values.push(validation);
            }
        })
        setErrors([...values]);
        return values.length === 0;
    }

    const clear = () => {
        setModel({
            firstname: '',
            lastname: '',
            email: '',
            active: false,
            password: '',
            confirm: ''
        });
    }

    const submit = async (): Promise<boolean> => {
        return new Promise(async resolve => {
            setSubmitted(true);
            setProcess(true);
            if (isValid() === false) {
                setProcess(false);
                resolve(false);
            }
            else {
                accountService.register(model.email, model.password).then(async id => {
                    let branduser: BrandUser = new BrandUser();
                    branduser.id = id;
                    branduser.brandid = brandid;
                    branduser.email = model.email;
                    branduser.firstname = model.firstname;
                    branduser.lastname = model.lastname;
                    branduser.active = model.active;
                    branduser.language = 'de';
                    await supplierUserService.set(id, branduser);

                    setSubmitted(false);
                    setProcess(false);
                    clear();

                    enqueueSnackbar("Brand User Registered", {
                        variant: "success",
                        anchorOrigin: {
                            vertical: "top",
                            horizontal: "right",
                        },
                    });

                    resolve(true);
                }).catch(err => {
                    let validation: Validation = new Validation();
                    validation.field = 'email';
                    validation.type = 'inuse';
                    validation.message = err === 'auth/email-already-in-use' ? 'Email already used/registered' : "Error encounter, pls refresh and try again"
                    setErrors([validation]);
                    setProcess(false);
                    resolve(false);
                })
            }
        });
    }

    return { model, change, submit, getError, getErrorMessage, process }
}

export default SupplierUserRegisterViewModel;